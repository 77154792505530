.fixed-icons {
    position: fixed;
    top: 50%;
    left: 2%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1000;
}

.icon-link{
    text-decoration: none;

}
.icon-circle {
    background-color: #3c2464; /* Circle color */
    border-radius: 50%;
    padding: 15px;
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
    transition: all 0.3s ease;
    overflow: hidden; /* Hide text initially */
    cursor: pointer;
}

.icon-circle .svg-inline--fa {
    color: #fff; /* Icon color */
    font-size: 20px; /* Icon size */
}

.icon-circle.hovered {
    border-radius: 25px; /* Make the icon container more oval-shaped */
    width: 150px; /* Expand the width on hover */
}

.icon-text {
    display: none; /* Hide the text by default */
    color: white;
    margin-left: 10px;
    font-size: 14px;
    transition: all 0.3s ease;
}

.icon-circle.hovered .icon-text {
    display: inline; /* Show the text when hovered */
}

.icon-circle.hovered .svg-inline--fa {
    margin-left: auto; /* Move the icon to the right when hovered */
}
