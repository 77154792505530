.slider {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #4a5568; 
    padding: 70px 0px;
}

.slider-container {
    width: 80%;
    max-width: 1200px;
}

.slick-slide img {
    margin: auto;
}

.slick-prev,
.slick-next {
    color: #6b46c1; 
}

.slick-dots li button:before {
    color: #6b46c1; 
}
.slider .title {
    font-size: 2.7rem;
}

.slide-item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    width: 100%; 
    margin: auto;

}

.slide-item img {
    width: 100%;
    height: 125px;
    display: block;
    margin-bottom: 25px;
}

.slick-prev::before, .slick-next::before {
    font-size: 30px; 
}

.slick-dots li.slick-active button:before {
    color: #4a5568; 
}

.slider-container .slick-slide {
    transition: transform 0.3s ease, opacity 0.3s ease;
    opacity: 0.5; 
}
  
.slider-container .slick-center {
    transform: scale(1.2); 
    opacity: 1; 
}

/* Media Queries for Responsive Adjustments */
@media (max-width: 1024px) {
    .slide-item {
        padding: 30px;
    }

    .slide-item img {
        height: 100px; 
    }
}

@media (max-width: 768px) {
    .slide-item {
        padding: 20px;
    }

    .slide-item img {
        height: 90px; 
    }
}

@media (max-width: 480px) {
    .slide-item {
        padding: 10px;
    }

    .slide-item img {
        height: 80px; 
    }
}
