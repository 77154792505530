

  
  .description {
    font-size: 18px;
    margin-bottom: 20px;
 
  }
  
  .serviceCardContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    gap: 16px;
    margin: 150px 0px;


  }
  
  .serviceCard {
    padding: 24px;
    border-right: 5px solid #7940B1;
    display: flex;
    margin-bottom: 20px;
    flex-direction: column;
    justify-content: space-between;
    text-align: right; /* Ensure text is right-aligned */
  }
  
  .serviceCard-text {
    font-weight: 700;
    font-size: 20px;

    margin-bottom: 16px;
  }
  
  .serviceCard-title {
    margin-bottom: 16px;
  }
  
  .serviceCard-button {
    background-color: #311356; /* Purple button background */
    color: white;
    font-weight: bold;
    width: 118.89px;
    height: 43px;
    margin-top: 20px;
    border: none;
    border-radius: 12px; /* Rounded button */
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .serviceCard-button:hover {
    background-color: #5A54D9; 
  }
  @media (max-width: 768px) {
    .serviceCardContainer {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 480px) {
    .serviceCardContainer {
      grid-template-columns: 1fr;
    }
  }
  

  .icon1 {
    margin-top: 70px;
  }
  
  .icon2 {
    margin-top: 135px;
  }

  .mx-auto{
    margin: 0 auto; 
    padding: 20px;
    width: 50%;
    text-align: center;
  }


  .services-container {
    padding: 20px;
    text-align: right;
  }

  .services-list {
    list-style-type: none; 
    padding: 0;
    margin: 0;
  }

  .service-item {
    font-size: 18px;
    margin-bottom: 10px;
    position: relative;
    padding-right: 25px;
    line-height: 1.6;
  }

  .service-item::before {
    content: '•';
    position: absolute;
    right: 0;
    font-size: 24px;
    color: #311356; 
  }
 

  
  /* Small screens (up to 768px) */
  @media (max-width: 768px) {
    .mx-auto {
      width: 90%; /* Adjust width for small screens */
      padding: 15px;
    }
  
   
   
  }
  
/* General container styles */
.subservice-detail-container {
  padding: 20px;
}

.service-intro {
  text-align: center;
  margin-bottom: 50px;
}

.service-image img {
  width: 100%;
  border-radius: 12px;
}

.service-title {
  font-size: 36px;
  font-weight: bold;
  margin: 20px 0;
  font-family: "Cairo", sans-serif !important;
  color: #311356;
}

.service-description {
  font-size: 18px;
  font-family: "Cairo", sans-serif !important;
  color: #555;
}

.financial-consultation {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #F3F3FF;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 1204px;
  margin: auto;
  margin-top: 169px;
  
}

.image-containers {
  position: relative;
  width: 100%;
  height: 100%;
}

.image-containers img {
  width: 100%;
  height: 100%;
  max-width: 432px;
  border-radius: 16px;
  border-radius: 10px;
  margin-right: 40px;
}

.content-container {
  padding: 20px;
  color: #333;
}

.content-container h2 {
  color: #502274;
  font-family: 'Tajawal', sans-serif;
  font-size: 24px;
  margin-bottom: 15px;
  font-size: 48px;
}

.content-container p {
  font-family: 'Tajawal', sans-serif;
  font-size: 16px;
  line-height: 1.6;
}
.governanceForm{
  width: 50%;
}
.financial-services {
  max-width: 1328px;
  margin: 20px auto;
  padding: 20px;
  margin-top: 40px;
  background-color: #fff;
  border-right: 5px solid #502274;
}

.financial-services h2 {
  text-align: center;
  color: #4A148C;
  margin-bottom: 30px;
  font-size: 24px;
}

.service-item {
  margin-bottom: 20px;
}

.service-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.service-number {
  font-size: 24px;
  color: #4A148C;
  background-color: #EDE7F6;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-left: 10px;
}

.service-header h3 {
  margin: 0;
  font-size: 18px;
  color: #4A148C;
}

.service-item p {
  margin: 0;
  font-size: 16px;
  color: #555;
}

@media (max-width: 600px) {
  .service-header {
      flex-direction: column;
      align-items: flex-start;
  }

  .service-number {
      margin-left: 0;
      margin-bottom: 5px;
  }
}