.blogger{
    position: relative;
}
.overlayy-text {
     position: absolute;
    top: 65%;
    /* left: 40%; */
    /* color: bisque; */
    transform: translate(-20%, -50%);
    /* padding: 20px; */

}
.overlayy-text h1 {
    color: #311356 !important;
    font-size: 64px !important;
    font-weight: 700;
    /* margin-right: 60px; */

}
.background input {
    border: #311356 solid 2px !important;
    border-radius: 16px;
    margin-top: 100px;
    margin-bottom: 60px;
    width: 85%;
    padding: 7px;
}

.input-with-icon {
    position: relative;
  }
  
  .input-with-icon input {
    padding-right: 3rem; /* Make space for the icon */
  }
  
  .input-with-icon .fa-magnifying-glass {
    position: absolute;
    right: 6.75rem;
    top: 60%;
    transform: translateY(-50%);
    pointer-events: none;
    width: 21px;
    height: 22px;
}

  /* NavigationMenu.css */
.blogger .nav-link {
    margin: 0 !important;
    color: #311356 ;
    font-size: 12px;
}
.bloggerNav{
    display: flex;
    justify-content: center;
        list-style-type: none; /* Remove bullets from the list */
        padding: 0; /* Remove default padding */
        display: flex; /* Align items horizontally */
        gap: 15px; /* Add space between list items */
    
      
}

.blogger .nav-link  {
    text-decoration: none !important;
}

ul{
    padding-right: 0px !important;
}

.BloggerCard {
    width: 350px;
    height: auto;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    transition: transform 0.3s;
    position: relative;
    overflow: hidden;
    cursor: pointer;
}
.BloggerCard:hover{
    transform: translateY(-10px);

}


.card-img-wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    flex-grow: 1;
}

.card-img-top {
    width: 100%;
    height: 100%;

}

.card-body {

    bottom: 0px;
    padding:  26px 36px 26px 36px;
    left: 27px;
    border-radius: 52px 0px 0px 0px;
    background-color: white;
}


.BloggerCard .card-title {
    font-size: 20px;
}
.BloggerCard span {
    font-size: 12px;
    color: #858585;
}
.BloggerCard  .card-text{
    font-size: 12px;
}
.BloggerCard .card-body img {
    padding-left: 20px;

}
.arrow-icon {
    position: absolute;
    bottom: 8px;
    left: 30px;
    color: #666666;
    font-size: 1.5rem;
    cursor: pointer;
    transition: color 0.3s;
  }
  
  .arrow-icon:hover {
    color: #333333;
  }
.cardGap{
    margin-bottom: 50px;
}
.cardContainer {
    margin: auto;
    width: 55%;

    margin-bottom: 50px;
}

@media (max-width: 768px) {
    .input-with-icon {
      max-width: 90%; /* Reduce max-width for tablets */
    }
  
    .input-with-icon input {
      font-size: 14px; /* Adjust font size for smaller screens */
      padding-right: 2.5rem; /* Adjust padding for smaller screen */
    }
  
    .input-with-icon .fa-magnifying-glass {
      width: 18px;
      height: 18px; /* Adjust icon size for smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .input-with-icon {
      max-width: 100%; /* Full width on very small screens */
    }
  
    .input-with-icon input {
      font-size: 12px; /* Further reduce font size for very small screens */
      padding-right: 2rem; /* Adjust padding for very small screens */
    }
  
    .input-with-icon .fa-magnifying-glass {
      width: 16px;
      height: 16px; /* Further adjust icon size for very small screens */
    }
  }
  @media (max-width: 1382px) {
    .BloggerCard .card-title {
      font-size: 23px;
    }
  }
