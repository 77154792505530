.card-grid {
  transform: translate(-2%, 0%);
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(385px, 0fr));
    gap: 20px;
    padding: 20px;
    justify-content: center;
  }
  
  .card {
    width: 350px;
    height: 400px;
    border-top: 10px solid #7940B1;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    display: flex;
    transition: transform 0.3s;
    flex-direction: column;
    align-items: center; 
    justify-content: center;
    position: relative;
    cursor: pointer;
    overflow: hidden;
  }
  
  .card-title,
  .card-description {
    margin: 0; 
  }
  
  .card-title {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: #1c103b;
  }
  
  .card-description {
    font-size: 1em;
    color: #333;
    padding-top: 20px;
    margin-bottom: 20px;
  }
  
  .card-button {
    background-color: #311356;
    color: white;
    border: none;
    padding: 10px 45px;
    font-size: 16px;
    border-radius: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    position: absolute;
    bottom: 20px; /* Fixed position from the bottom of the card */
    left: 50%;
    transform: translateX(-50%); /* Center the button horizontally */
  }
  .card:hover {
    transform: translateY(-10px);
  }
  
  .card-button:hover {
    background-color: #4620a3;
  }
  .Services{
    margin-top: 170px;
  }